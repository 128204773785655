import { useTranslation } from 'react-i18next';
import './LanguageSelector.css'

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="language-selector">
      <select onChange={(e) => changeLanguage(e.target.value)} defaultValue={i18n.language}>
        <option value="en">EN</option>
        <option value="fr">FR</option>
        <option value="nl">NL</option>
      </select>
    </div>
  );
};

export default LanguageSelector;
