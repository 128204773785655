import React from 'react';
import './Header.css';
import LanguageSelector from '../translations/LanguageSelector';

const Header = () => {
  return (
    <div>
        <header className="custom-header">
      <h1>Valogen</h1>
      <nav>
        <ul>
        </ul>
      </nav>
    </header>
    
    <LanguageSelector />
    </div>
  );
};

export default Header;