import React from 'react';
import logo from '../../logo.png';

const Logo: React.FC = () => {

  return (
    <div>
        <img src={logo} className="App-logo" alt="logo" />
    </div>
  );
};

export default Logo;
        