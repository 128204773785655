// Footer.tsx
import React from 'react';
import './Footer.css'; // Import CSS for styling
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <p>{t('footer.copyright')}</p>
    </footer>
  );
};

export default Footer;
