import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../../locales/en/translation.json';
import translationFR from '../../locales/fr/translation.json';
import translationNL from '../../locales/nl/translation.json';

const resources = {
  en: { translation: translationEN },
  fr: { translation: translationFR },
  nl: { translation: translationNL },
};

const language = localStorage.getItem('language') || 'en';

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: language,
    interpolation: { escapeValue: false },
    react: {
      useSuspense: false,
    }
  });

i18n.on('languageChanged', (lng) => {
  localStorage.setItem('language', lng);
});

export default i18n;
