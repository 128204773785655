
import './App.css';
import SignUpForm from './components/SignUpForm/SignUpForm';
import Footer from './components/Footer/Footer';
import Logo from './components/Logo/Logo';
import Welcome from './components/Welcome/Welcome';
import Header from './components/Header/Header';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='App-content'>
      <Header/>
        <Logo />
        <Welcome />
        <SignUpForm />
        <Footer />
        </div>
      </header>
    </div>
  );
}

export default App;
