import React from 'react';
import { useTranslation } from 'react-i18next';

const SignedUp: React.FC = () => {
    const { t } = useTranslation();
  return (
    <div>
        <p style={{ fontSize: 'calc(6px + 1vmin)' }}>{t('signupform.signupSuccess')}</p>
        <p style={{ fontSize: 'calc(6px + 1vmin)' }}>{t('signupform.signupSuccessAdditionalMsg')}</p>
    </div>
  );
};

export default SignedUp;
