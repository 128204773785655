import React, { useState } from 'react';
import './SignUpForm.css';
import SignedUp from '../SignedUpForm/SignedUpForm';
import { useTranslation } from 'react-i18next';
import AlreadySignedUp from '../AlreadySignedUp/AlreadySignedUp';

const SignUpForm: React.FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [isAlreadySignedUp, setIsAlreadySignedUp] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the default form submission behavior

    if (email) {
      try {
        const response = await fetch('https://valogen-prd-backend.azurewebsites.net/subscribe', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        if (response.ok) {
          setIsSignedUp(true);
        } else if (response.status === 409) {
          // Handle 409 Conflict status code
          setIsAlreadySignedUp(true);
        } else {
          console.error('Error:', response.statusText);
          alert('Failed to sign up, please try again later.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred, please try again later.');
      }
    } else {
      alert('Please enter a valid email address.');
    }
  };

  return (
    <div>
      {isSignedUp ? ( // Conditionally render the SignedUp component
        <SignedUp />
      ) : isAlreadySignedUp ? ( // Conditionally render the AlreadySignedUp component
        <AlreadySignedUp />
      ) : (
        <div>
          <p style={{ fontSize: 'calc(6px + 1vmin)' }}>{t('signupform.requestMsg')}</p>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              id="email"
              name="email"
              placeholder={t('signupform.enterEmail')}
              value={email}
              onChange={(e) => setEmail(e.target.value)} // Update the email state
              required
            />
            <button type="submit" className="signup-button">{t('signupform.signupButton')}</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default SignUpForm;
