import React from 'react';
import { useTranslation } from 'react-i18next';

const Welcome: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
        <p style={{ marginBottom: 'calc(48px + 2vmin)' }}>{t('welcome.empowering')} <span style={{ fontWeight: 'bold' }}>{t('welcome.business')} </span>{t('welcome.with')} <span style={{ fontWeight: 'bold' }}>{t('welcome.innovation')}</span>
</p>
    </div>
  );
};

export default Welcome;